// extracted by mini-css-extract-plugin
export var disclaimer = "product-details-module--disclaimer--065d1";
export var disclaimerImage = "product-details-module--disclaimer-image--fc948";
export var errorBlink = "product-details-module--error-blink--b63bf";
export var group = "product-details-module--group--6e6ab";
export var image = "product-details-module--image--54cfe";
export var imageRatio = "product-details-module--image-ratio--773b0";
export var large = "product-details-module--large--1431b";
export var link = "product-details-module--link--36a99";
export var text = "product-details-module--text--e1948";
export var title = "product-details-module--title--8e041";
export var wrapper = "product-details-module--wrapper--0c392";